// In frontend/src/components/CampaignForm.js
import React, { useState, useEffect } from 'react';
import axiosInstance from '../axiosConfig'; 
import { useNavigate, useParams } from 'react-router-dom';
import {
    TextField,
    Button,
    Card,
    CardContent,
    Typography,
    Grid,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from '@mui/material';

const CampaignForm = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [creatorId, setCreatorId] = useState('');
    const [duration, setDuration] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [details, setDetails] = useState('');
    const [budget, setBudget] = useState('');
    const [selectedNiches, setSelectedNiches] = useState([]);

    const [creators, setCreators] = useState([]);
    const [niches, setNiches] = useState([]);

    useEffect(() => {
        const fetchCreators = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await axiosInstance.get('/api/creators/', {
                    headers: {
                        Authorization: `Token ${token}`,
                    },
                });
                setCreators(response.data);
            } catch (error) {
                console.error('Error fetching creators:', error);
            }
        };

        const fetchNiches = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await axiosInstance.get('/api/niches/', {
                    headers: {
                        Authorization: `Token ${token}`,
                    },
                });
                setNiches(response.data);
            } catch (error) {
                console.error('Error fetching niches:', error);
            }
        };

        const fetchCampaign = async () => {
            if (id) {
                try {
                    const token = localStorage.getItem('token');
                    const response = await axiosInstance.get(`/api/campaigns/${id}/`, {
                        headers: {
                            Authorization: `Token ${token}`,
                        },
                    });
                    setCreatorId(response.data.creator.id);
                    setDuration(response.data.duration);
                    setStartDate(response.data.start_date);
                    setEndDate(response.data.end_date);
                    setDetails(response.data.details);
                    setBudget(response.data.budget);
                    setSelectedNiches(response.data.niches.map(niche => niche.id));
                } catch (error) {
                    console.error('Error fetching campaign:', error);
                }
            }
        };

        fetchCreators();
        fetchNiches();
        fetchCampaign();
    }, [id]);

    const handleNichesChange = (event) => {
        setSelectedNiches(Array.from(event.target.selectedOptions).map(option => parseInt(option.value)));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const token = localStorage.getItem('token');
            const data = {
                creator: creatorId,
                duration,
                start_date: startDate,
                end_date: endDate,
                details,
                budget,
                niches: selectedNiches, 
            };

            if (id) {
                await axiosInstance.put(`/api/campaigns/${id}/`, data, {
                    headers: {
                        Authorization: `Token ${token}`,
                    },
                });
                alert('Campaign updated successfully!');
            } else {
                await axiosInstance.post('/api/campaigns/', data, {
                    headers: {
                        Authorization: `Token ${token}`,
                    },
                });
                alert('Campaign created successfully!');
            }
            navigate('/campaigns');
        } catch (error) {
            console.error('Error submitting campaign:', error);
            alert('Error submitting campaign. Please try again.');
        }
    };

    return (
        <Card>
            <CardContent>
                <Typography variant="h5" component="h2">
                    {id ? 'Edit Campaign' : 'Create Campaign'}
                </Typography>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth>
                                <InputLabel id="creator-select-label">Creator</InputLabel>
                                <Select
                                    labelId="creator-select-label"
                                    id="creator-select"
                                    value={creatorId}
                                    onChange={(e) => setCreatorId(e.target.value)}
                                >
                                    {creators.map((creator) => (
                                        <MenuItem key={creator.id} value={creator.id}>
                                            {creator.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Duration (days)"
                                type="number"
                                value={duration}
                                onChange={(e) => setDuration(e.target.value)}
                                fullWidth
                                margin="normal"
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Start Date"
                                type="date"
                                value={startDate}
                                onChange={(e) => setStartDate(e.target.value)}
                                fullWidth
                                margin="normal"
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label="End Date"
                                type="date"
                                value={endDate}
                                onChange={(e) => setEndDate(e.target.value)}
                                fullWidth
                                margin="normal"
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Campaign Details"
                                multiline
                                rows={4}
                                value={details}
                                onChange={(e) => setDetails(e.target.value)}
                                fullWidth
                                margin="normal"
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Budget (KES)"
                                type="number"
                                value={budget}
                                onChange={(e) => setBudget(e.target.value)}
                                fullWidth
                                margin="normal"
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel id="niches-select-label">Niches</InputLabel>
                                <Select
                                    labelId="niches-select-label"
                                    id="niches-select"
                                    multiple
                                    value={selectedNiches}
                                    onChange={handleNichesChange}
                                >
                                    {niches.map((niche) => (
                                        <MenuItem key={niche.id} value={niche.id}>
                                            {niche.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Button type="submit" variant="contained" color="primary">
                        {id ? 'Update' : 'Create'}
                    </Button>
                </form>
            </CardContent>
        </Card>
    );
};

export default CampaignForm;