// /frontend/src/components/LandingPage.js
import React from 'react';
import { Link } from 'react-router-dom';
import './LandingPage.css'; 

const LandingPage = () => {
    return (
        <div className="landing-page">
            <div className="jumbotron text-center">
                <h1>Welcome to InfluenceHub</h1>
                <p>Your one-stop solution for influencer marketing</p>
                <Link to="/register" className="btn btn-primary">
                    Get Started
                </Link>
            </div>
        </div>
    );
};

export default LandingPage;