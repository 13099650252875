// /frontend/src/index.js
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';

document.addEventListener('DOMContentLoaded', () => {
    // Fetch user data for the session after login and store in window.user
    const token = localStorage.getItem('token');
    if (token) {
        axios.get('/api/users/profile/', {
            headers: {
                Authorization: `Token ${token}`,
            },
        })
        .then(response => {
            window.user = response.data; 
            console.log("User data:", window.user); 
        })
        .catch(error => {
            console.error("Error fetching user data:", error);
        });
    }

    reportWebVitals();
    const root = ReactDOM.createRoot(document.getElementById('root'));
    root.render(
      <React.StrictMode>
          <App />
      </React.StrictMode>
  );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals