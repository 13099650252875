// frontend/src/components/Login.js
import React, { useState } from 'react';
import axiosInstance from '../axiosConfig'; 
import { useNavigate } from 'react-router-dom';
import {
    TextField,
    Button,
    Card,
    CardContent,
    Typography,
} from '@mui/material';

const Login = () => {
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axiosInstance.post('/api/auth/jwt/create/', { // Djoser JWT login endpoint
                username,
                password,
            });

            console.log("Login Response:", response);  

            if (response.status === 200) {
                // Store the JWT token in localStorage
                localStorage.setItem('token', response.data.access); // Access token from Djoser 

                // Fetch user data 
                const userResponse = await axiosInstance.get('/api/users/profile/', {
                    headers: {
                        Authorization: `JWT ${response.data.access}` 
                    }
                });
                console.log("User data response", userResponse);
                localStorage.setItem('userId', userResponse.data.id);
                localStorage.setItem('username', userResponse.data.username);
                localStorage.setItem('isCreator', userResponse.data.is_creator);
                localStorage.setItem('isBrand', userResponse.data.is_brand);

                console.log("Stored token:", localStorage.getItem('token'));
                console.log("User ID:", localStorage.getItem('userId'));
                console.log("Username:", localStorage.getItem('username'));
                console.log("isCreator:", localStorage.getItem('isCreator'));
                console.log("isBrand:", localStorage.getItem('isBrand'));

                navigate(userResponse.data.is_brand ? '/campaigns' : '/creators');
                setMessage('Login successful!');
                setTimeout(() => {
                    setMessage('');
                }, 2000);
            } else {
                console.error("Login failed. Unexpected status code:", response.status);
                setMessage('Login failed. Please check your credentials.');
            }

        } catch (error) {
            console.error('Login Error:', error);
            if (error.response) {
                console.error('Response Data:', error.response.data);
                console.error('Response Status:', error.response.status);
            }
            setMessage('Error logging in. Please check your credentials.');
        }
    };

  return (
    <Card>
      <CardContent>
        <Typography variant="h5" component="h2">
          Login
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            label="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            fullWidth
            margin="normal"
            required
          />
          <TextField
            label="Password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            fullWidth
            margin="normal"
            required
          />
          <Button type="submit" variant="contained" color="primary">
            Login
          </Button>
        </form>
        {message && <p>{message}</p>}
      </CardContent>
    </Card>
  );
};

export default Login;