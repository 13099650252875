// /frontend/src/components/PaymentForm.js
import React, { useState } from 'react';
import axios from 'axios';
import { TextField, Button, Card, CardContent, Typography } from '@mui/material';
import PaystackPop from '@paystack/inline-js';
import axiosInstance from '../axiosConfig';

const PaymentForm = ({ amount }) => {
    const [email, setEmail] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const token = localStorage.getItem('token');
            const { data } = await axios.post('/api/payments/initiate/', {
                amount,
                email,
            }, {
                headers: {
                    Authorization: `Token ${token}`,
                },
            });
            const { reference } = data.data;

            const handler = PaystackPop.setup({
                key: 'pk_test_455ae2ef6dbeeae241b04d8639a37f9ab7c7f07d', // Replace with your public key
                email: email,
                amount: amount * 100, // Convert to kobo
                currency: 'KES',
                ref: reference,
                callback: function (response) {
                    axiosInstance
                        .get(`/api/payments/verify/${response.reference}/`)
                        .then((res) => {
                            alert('Payment successful!');
                        })
                        .catch((error) => {
                            console.error('Payment verification failed:', error);
                        });
                },
                onClose: function () {
                    alert('Transaction was not completed, window closed.');
                },
            });

            handler.openIframe();
        } catch (error) {
            console.error('Payment initiation failed:', error);
        }
    };

    return (
        <Card>
            <CardContent>
                <Typography variant="h6" component="h2">
                    Make a Payment
                </Typography>
                <form onSubmit={handleSubmit}>
                    <TextField
                        label="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        fullWidth
                        margin="normal"
                        required
                    />
                    <Button type="submit" variant="contained" color="primary">
                        Pay
                    </Button>
                </form>
            </CardContent>
        </Card>
    );
};

export default PaymentForm;