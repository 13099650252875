// /frontend/src/components/CampaignList.js
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axiosInstance from '../axiosConfig'; 
import {
    List,
    ListItem,
    ListItemText,
    Typography,
    Button,
    Grid,
    TextField,
} from '@mui/material';

const CampaignList = () => {
    const navigate = useNavigate();
    const [campaigns, setCampaigns] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

    // eslint-disable-next-line no-unused-vars
    const handleCampaignClick = (campaign) => {
        navigate(`/campaigns/${campaign.id}`);
    };

    useEffect(() => {
        const fetchCampaigns = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await axiosInstance.get('/api/campaigns/', {
                    headers: {
                        Authorization: `Token ${token}`,
                    },
                });
                setCampaigns(response.data);
            } catch (error) {
                console.error('Error fetching campaigns:', error);
            }
        };

        fetchCampaigns();
    }, []);

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
        const token = localStorage.getItem('token');
        axiosInstance
            .get(`/api/campaigns/?search=${e.target.value}`, {
                headers: {
                    Authorization: `Token ${token}`,
                },
            })
            .then((response) => {
                setCampaigns(response.data);
            })
            .catch((error) => {
                console.error('Error searching campaigns:', error);
            });
    };

    return (
        <div>
            <Typography variant="h4" gutterBottom>
                Campaigns
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Button
                        component={Link}
                        to="/campaigns/create"
                        variant="contained"
                        color="primary"
                    >
                        Create Campaign
                    </Button>
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        label="Search Campaigns..."
                        value={searchTerm}
                        onChange={handleSearch}
                        fullWidth
                        margin="normal"
                        variant="outlined"
                    />
                </Grid>
            </Grid>
            <List>
                {campaigns.map((campaign) => (
                    <ListItem
                        button
                        component={Link}
                        to={`/campaigns/${campaign.id}`}
                        key={campaign.id}
                    >
                        <ListItemText
                            primary={campaign.details}
                            secondary={`Creator: ${campaign.creator.name}`}
                        />
                    </ListItem>
                ))}
            </List>
        </div>
    );
};

export default CampaignList;