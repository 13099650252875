// /frontend/src/components/CreatorList.js
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axiosInstance from '../axiosConfig'; 
import { TextField, List, ListItem, ListItemText, Typography } from '@mui/material';

const CreatorList = () => {
    // eslint-disable-next-line no-unused-vars
    const navigate = useNavigate();
    const [creators, setCreators] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        const fetchCreators = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await axiosInstance.get('/api/creators/', {
                    headers: {
                        Authorization: `Token ${token}`,
                    },
                });
                setCreators(response.data);
            } catch (error) {
                console.error('Error fetching creators:', error);
            }
        };

        fetchCreators();
    }, []);

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
        const token = localStorage.getItem('token');
        axiosInstance
            .get(`/api/creators/?search=${e.target.value}`, {
                headers: {
                    Authorization: `Token ${token}`,
                },
            })
            .then((response) => {
                setCreators(response.data);
            })
            .catch((error) => {
                console.error('Error searching creators:', error);
            });
    };

    return (
        <div>
            <Typography variant="h4" gutterBottom>
                Creators
            </Typography>
            <TextField
                type="text"
                label="Search creators..."
                value={searchTerm}
                onChange={handleSearch}
                fullWidth
                margin="normal"
                variant="outlined"
            />
            <List>
                {creators.map((creator) => (
                    <ListItem
                        button
                        component={Link}
                        to={`/creators/${creator.id}`}
                        key={creator.id}
                    >
                        <ListItemText
                            primary={creator.name}
                            secondary={`Platform: ${creator.social_media_platform}`}
                        />
                    </ListItem>
                ))}
            </List>
        </div>
    );
};

export default CreatorList;