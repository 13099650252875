import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, Navigate, useNavigate } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Container, Button, CssBaseline, ThemeProvider } from '@mui/material';
import CreatorList from './components/CreatorList';
import CampaignList from './components/CampaignList';
import CreatorDetail from './components/CreatorDetail';
import CampaignDetail from './components/CampaignDetail';
import CampaignForm from './components/CampaignForm';
import CampaignReport from './components/CampaignReport';
import PaymentForm from './components/PaymentForm';
import Register from './components/Register';
import Login from './components/Login';
import LandingPage from './components/LandingPage';
import theme from './theme';
import axiosInstance from './axiosConfig'; 

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState(null);
  const navigate = useNavigate(); // Access the navigate function

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setIsLoggedIn(true);
      setUser({
        userId: localStorage.getItem('userId'),
        username: localStorage.getItem('username'),
        isCreator: localStorage.getItem('isCreator') === 'true',
        isBrand: localStorage.getItem('isBrand') === 'true',
      });
      axiosInstance.defaults.headers.common['Authorization'] = `Token ${token}`;
    }

    // Intercept 401 errors and redirect to login
    const responseInterceptor = axiosInstance.interceptors.response.use(
      response => response,
      error => {
        if (error.response && error.response.status === 401) {
          handleLogout(); // Log the user out
          navigate('/login'); // Redirect to login page
          return Promise.reject(error); // Continue rejecting the error
        }
        return Promise.reject(error); // Reject other errors
      }
    );

    return () => {
      axiosInstance.interceptors.response.eject(responseInterceptor);
    };
  }, [navigate]); // Add navigate to the dependency array

  const handleLogout = () => {
    localStorage.clear();
    setIsLoggedIn(false);
    setUser(null);
    delete axiosInstance.defaults.headers.common['Authorization'];
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <div>
          <AppBar position="static">
            <Toolbar>
              <Typography variant="h6" style={{ flexGrow: 1 }}>
                InfluenceHub
              </Typography>
              {isLoggedIn ? (
                <>
                  {user.isBrand && (
                    <Button color="inherit" component={Link} to="/campaigns">
                      Campaigns
                    </Button>
                  )}
                  <Button color="inherit" onClick={handleLogout}>
                    Logout
                  </Button>
                </>
              ) : (
                <>
                  <Button color="inherit" component={Link} to="/login">
                    Login
                  </Button>
                  <Button color="inherit" component={Link} to="/register">
                    Register
                  </Button>
                </>
              )}
            </Toolbar>
          </AppBar>
          <Container>
            <Routes>
              <Route path="/" element={<LandingPage />} />
              <Route path="/creators/:id" element={isLoggedIn ? <CreatorDetail /> : <Navigate to="/login" replace />} />
              <Route path="/creators" element={isLoggedIn ? <CreatorList /> : <Navigate to="/login" replace />} />
              <Route path="/campaigns/create" element={isLoggedIn ? <CampaignForm /> : <Navigate to="/login" replace />} />
              <Route path="/campaigns/edit/:id" element={isLoggedIn ? <CampaignForm /> : <Navigate to="/login" replace />} />
              <Route path="/campaigns/:id" element={isLoggedIn ? <CampaignDetail /> : <Navigate to="/login" replace />} />
              <Route path="/campaigns" element={isLoggedIn ? <CampaignList /> : <Navigate to="/login" replace />} />
              <Route path="/report/:id" element={isLoggedIn ? <CampaignReport /> : <Navigate to="/login" replace />} />
              <Route path="/payment" element={isLoggedIn ? <PaymentForm amount={1000} /> : <Navigate to="/login" replace />} />
              <Route path="/register" element={<Register />} />
              <Route path="/login" element={<Login />} />
            </Routes>
          </Container>
        </div>
      </Router>
    </ThemeProvider>
  );
}

export default App;