// /frontend/src/components/CampaignReport.js
import React, { useEffect, useState } from 'react';
import axiosInstance from '../axiosConfig'; 
import { useParams } from 'react-router-dom';
import { Typography, Card, CardContent } from '@mui/material';

const CampaignReport = () => {
    const { id } = useParams();
    const [report, setReport] = useState(null);

    useEffect(() => {
        const fetchReport = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await axiosInstance.get(`/api/campaigns/${id}/report/`, {
                    headers: {
                        Authorization: `Token ${token}`,
                    },
                });
                setReport(response.data);
            } catch (error) {
                console.error('There was an error fetching the campaign report!', error);
            }
        };

        fetchReport();
    }, [id]);

    if (!report) return <div>Loading...</div>;

    return (
        <Card>
            <CardContent>
                <Typography variant="h5" component="h2">
                    Campaign Report
                </Typography>
                <Typography variant="h6">Name: {report.name}</Typography>
                <Typography variant="body1">Description: {report.description}</Typography>
                <Typography variant="body1">
                    Start Date: {report.start_date}
                </Typography>
                <Typography variant="body1">
                    End Date: {report.end_date}
                </Typography>
                <Typography variant="h6">Analytics:</Typography>
                <Typography variant="body1">Views: {report.analytics.views}</Typography>
                <Typography variant="body1">
                    Engagements: {report.analytics.engagements}
                </Typography>
                <Typography variant="body1">
                    Conversions: {report.analytics.conversions}
                </Typography>
                <Typography variant="h6">Payments:</Typography>
                <Typography variant="body1">
                    Total Payments: {report.total_payments} KES
                </Typography>
            </CardContent>
        </Card>
    );
};

export default CampaignReport;