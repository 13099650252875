// /frontend/src/components/CampaignDetail.js
import React, { useEffect, useState } from 'react';
import axiosInstance from '../axiosConfig'; 
import { useParams, useNavigate } from 'react-router-dom';
import {
    TextField,
    Button,
    // eslint-disable-next-line no-unused-vars
    Card,
    // eslint-disable-next-line no-unused-vars
    CardContent,
    // eslint-disable-next-line no-unused-vars
    Typography,
    // eslint-disable-next-line no-unused-vars
    Grid,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from '@mui/material';

const CampaignDetail = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [campaign, setCampaign] = useState(null);
    const [status, setStatus] = useState('');
    const [amount, setAmount] = useState(0);

    useEffect(() => {
        const fetchCampaign = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await axiosInstance.get(`/api/campaigns/${id}/`, {
                    headers: {
                        Authorization: `Token ${token}`,
                    },
                });
                setCampaign(response.data);
            } catch (error) {
                console.error('There was an error fetching the campaign details!', error);
            }
        };

        fetchCampaign();
    }, [id]);

    const handleStatusChange = (event) => {
        setStatus(event.target.value);
    };

    const handleAmountChange = (event) => {
        setAmount(parseInt(event.target.value, 10));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const token = localStorage.getItem('token'); 
            await axiosInstance.put(`/api/campaigns/${id}/`, { status, budget: amount }, {
                headers: {
                    Authorization: `Token ${token}`,
                },
            });
            navigate('/campaigns');
            alert('Campaign updated successfully');
        } catch (error) {
            console.error('There was an error updating the campaign!', error);
        }
    };

    if (!campaign) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            <h1>Campaign Detail</h1>
            <p>
                <strong>Creator:</strong> {campaign.creator.name}
            </p>
            <p>
                <strong>Brand:</strong> {campaign.brand.name}
            </p>
            <p>
                <strong>Duration:</strong> {campaign.duration} days
            </p>
            <p>
                <strong>Start Date:</strong> {campaign.start_date}
            </p>
            <p>
                <strong>End Date:</strong> {campaign.end_date}
            </p>
            <p>
                <strong>Details:</strong> {campaign.details}
            </p>
            <p>
                <strong>Budget:</strong> {campaign.budget} KES
            </p>
            <p>
                <strong>Status:</strong> {campaign.status}
            </p>
            <h2>Update Campaign</h2>
            <form onSubmit={handleSubmit}>
                <FormControl fullWidth>
                    <InputLabel id="status-select-label">Status</InputLabel>
                    <Select
                        labelId="status-select-label"
                        id="status-select"
                        value={status}
                        onChange={handleStatusChange}
                    >
                        <MenuItem value="pending">Pending</MenuItem>
                        <MenuItem value="accepted">Accepted</MenuItem>
                        <MenuItem value="rejected">Rejected</MenuItem>
                        <MenuItem value="completed">Completed</MenuItem>
                    </Select>
                </FormControl>
                <br />
                <br />
                <TextField
                    label="Budget (KES)"
                    type="number"
                    value={amount}
                    onChange={handleAmountChange}
                    fullWidth
                    margin="normal"
                />
                <br />
                <Button type="submit" variant="contained" color="primary">
                    Update
                </Button>
            </form>
        </div>
    );
};

export default CampaignDetail;