// /frontend/src/components/ContactForm.js
import React, { useState } from 'react';
import axiosInstance from '../axiosConfig'; 
import { TextField, Button, Card, CardContent, Typography } from '@mui/material';

const ContactForm = ({ creatorId }) => {
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [email, setEmail] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const token = localStorage.getItem('token');
            await axiosInstance.post(`/api/creators/${creatorId}/contact/`, {
                subject,
                message,
                email,
            }, {
                headers: {
                    Authorization: `Token ${token}`,
                },
            });
            alert('Message sent successfully!');
        } catch (error) {
            console.error('There was an error sending the message!', error);
        }
    };

    return (
        <Card>
            <CardContent>
                <Typography variant="h6" component="h2">
                    Contact Creator
                </Typography>
                <form onSubmit={handleSubmit}>
                    <TextField
                        label="Subject"
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                        fullWidth
                        margin="normal"
                        required
                    />
                    <TextField
                        label="Message"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        fullWidth
                        margin="normal"
                        required
                        multiline
                        rows={4}
                    />
                    <TextField
                        label="Your Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        fullWidth
                        margin="normal"
                        required
                    />
                    <Button type="submit" variant="contained" color="primary">
                        Send
                    </Button>
                </form>
            </CardContent>
        </Card>
    );
};

export default ContactForm;