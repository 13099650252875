// /frontend/src/components/CreatorDetail.js
import React, { useEffect, useState } from 'react';
import axiosInstance from '../axiosConfig'; 
import { useParams } from 'react-router-dom';
// eslint-disable-next-line no-unused-vars
import { Typography, Card, CardContent, Button, Grid, TextField, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import CreatorAnalytics from './CreatorAnalytics';
import ContactForm from './ContactForm';

const CreatorDetail = () => {
    const { id } = useParams();
    const [creator, setCreator] = useState(null);
    const [analytics, setAnalytics] = useState(null);
    const [tier, setTier] = useState('');

    useEffect(() => {
        const fetchCreator = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await axiosInstance.get(`api/creators/${id}/`, {
                    headers: {
                        Authorization: `Token ${token}`,
                    },
                });
                setCreator(response.data);
                setTier(response.data.user.tier); 
            } catch (error) {
                console.error('There was an error fetching the creator!', error);
            }
        };

        const fetchAnalytics = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await axiosInstance.get(`api/creators/${id}/analytics/`, {
                    headers: {
                        Authorization: `Token ${token}`,
                    },
                });
                setAnalytics(response.data);
            } catch (error) {
                console.error('There was an error fetching the creator analytics!', error);
            }
        };

        fetchCreator();
        fetchAnalytics();
    }, [id]);

    const handleTierChange = (event) => {
        setTier(event.target.value);
    };

    const updateCreatorTier = async () => {
        try {
            const token = localStorage.getItem('token');
            await axiosInstance.put(`api/creators/${id}/tier/`, { tier }, {
                headers: {
                    Authorization: `Token ${token}`,
                },
            });
            alert('Creator tier updated successfully!');
        } catch (error) {
            console.error('There was an error updating the creator tier!', error);
            alert('Error updating tier. Please try again.');
        }
    };

    if (!creator) return <div>Loading...</div>;

    return (
        <div>
            <Typography variant="h4" gutterBottom>{creator.name}</Typography>
            <Card>
                <CardContent>
                    <Typography variant="body1">Description: {creator.bio}</Typography>
                    <Typography variant="body1">Platform: {creator.social_media_platform}</Typography>
                    <Typography variant="body1">Followers: {creator.followers}</Typography>
                    <Typography variant="body1">Price per Campaign: {creator.price_per_campaign} KES</Typography>
                </CardContent>
            </Card>
            {analytics && <CreatorAnalytics analytics={analytics} />}
            <ContactForm creatorId={id} />
            {creator.user === window.user.userId && ( 
                <div>
                    <Grid container spacing={2} style={{ marginTop: '20px' }}>
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth>
                                <InputLabel id="tier-select-label">Tier</InputLabel>
                                <Select
                                    labelId="tier-select-label"
                                    id="tier-select"
                                    value={tier}
                                    onChange={handleTierChange}
                                >
                                    <MenuItem value="basic">Basic</MenuItem>
                                    <MenuItem value="pro">Pro</MenuItem>
                                    <MenuItem value="premium">Premium</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Button variant="contained" color="primary" onClick={updateCreatorTier}>Update Tier</Button>
                        </Grid>
                    </Grid>
                </div>
            )}
        </div>
    );
};

export default CreatorDetail;