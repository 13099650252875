// frontend/src/axiosConfig.js
import axios from 'axios';

// You can remove this function and the `withCredentials` option from `axiosInstance`
// if you are not using CSRF tokens.
const getCsrfToken = () => {
    const cookie = document.cookie.match(/csrftoken=([^;]+)/);
    return cookie ? cookie[1] : null;
};

const axiosInstance = axios.create({
    baseURL: 'https://influencehub-o4lw37avvq-ew.a.run.app/api/', // Replace with your API URL
    headers: {
        'Content-Type': 'application/json', 
    },
    // You can remove this option if you are not using CSRF tokens
    withCredentials: true, 
});

axiosInstance.interceptors.request.use(
    (config) => {
        // Get the token for each request 
        const token = localStorage.getItem('token');
        if (token) {
            config.headers['Authorization'] = `Token ${token}`;
        }
        const csrfToken = getCsrfToken();
        if (csrfToken) {
            config.headers['X-CSRFToken'] = csrfToken;
        }
        return config;
    },
    (error) => {
        console.error(error);
        return Promise.reject(error);
    }
);

export default axiosInstance;