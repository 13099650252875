// frontend/src/components/Register.js
import React, { useState } from 'react';
import axiosInstance from '../axiosConfig'; 
import { useNavigate } from 'react-router-dom';
import {
    TextField,
    Button,
    Card,
    CardContent,
    Typography,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from '@mui/material';

const Register = () => {
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    // eslint-disable-next-line no-unused-vars
    const [email, setEmail] = useState(''); // Add email field
    const [isCreator, setIsCreator] = useState(false);
    const [isBrand, setIsBrand] = useState(false);
    const [message, setMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await axiosInstance.post('/api/auth/users/', { // Djoser user registration endpoint
                username,
                password,
                email, // Include email in registration data
                is_creator: isCreator,
                is_brand: isBrand,
            });
            setMessage('Registration successful! You can now log in.');
            setTimeout(() => {
                setMessage('');
                navigate('/login');
            }, 2000);
        } catch (error) {
            console.error('There was an error registering!', error);
            if (error.response) {
                console.error('Response Data:', error.response.data);
            }
            setMessage('Error registering. Please try again.');
        }
    };

    return (
        <Card>
            <CardContent>
                <Typography variant="h5" component="h2">
                    Register
                </Typography>
                <form onSubmit={handleSubmit}>
                    <TextField
                        label="Username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        fullWidth
                        margin="normal"
                        required
                    />
                    <TextField
                        label="Password"
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        fullWidth
                        margin="normal"
                        required
                    />
                    <FormControl fullWidth>
                        <InputLabel id="account-type-select-label">Account Type</InputLabel>
                        <Select
                            labelId="account-type-select-label"
                            id="account-type-select"
                            value={isCreator ? 'creator' : isBrand ? 'brand' : ''}
                            onChange={(e) => {
                                if (e.target.value === 'creator') {
                                    setIsCreator(true);
                                    setIsBrand(false);
                                } else if (e.target.value === 'brand') {
                                    setIsBrand(true);
                                    setIsCreator(false);
                                }
                            }}
                        >
                            <MenuItem value="creator">Creator</MenuItem>
                            <MenuItem value="brand">Brand</MenuItem>
                        </Select>
                    </FormControl>
                    <Button type="submit" variant="contained" color="primary">
                        Register
                    </Button>
                </form>
                {message && <p>{message}</p>}
            </CardContent>
        </Card>
    );
};

export default Register;