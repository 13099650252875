// /frontend/src/components/CreatorAnalytics.js
/* eslint-disable no-unused-vars */
import React from 'react';
import { Line } from 'react-chartjs-2';
import {
    Card,
    CardContent,
    Typography,
    Grid,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from '@mui/material';

const CreatorAnalytics = ({ analytics }) => {
    const data = {
        labels: analytics.dates,
        datasets: [
            {
                label: 'Followers',
                data: analytics.followers,
                fill: false,
                backgroundColor: 'blue',
                borderColor: 'blue',
            },
            {
                label: 'Engagements',
                data: analytics.engagements,
                fill: false,
                backgroundColor: 'green',
                borderColor: 'green',
            },
            {
                label: 'Views',
                data: analytics.views,
                fill: false,
                backgroundColor: 'orange',
                borderColor: 'orange',
            },
            {
                label: 'Likes',
                data: analytics.likes,
                fill: false,
                backgroundColor: 'red',
                borderColor: 'red',
            },
        ],
    };

    return (
        <Card>
            <CardContent>
                <Typography variant="h5" component="h2">
                    Creator Analytics
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Line data={{ labels: analytics.dates, datasets: [data.datasets[0]] }} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Line data={{ labels: analytics.dates, datasets: [data.datasets[1]] }} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Line data={{ labels: analytics.dates, datasets: [data.datasets[2]] }} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Line data={{ labels: analytics.dates, datasets: [data.datasets[3]] }} />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default CreatorAnalytics;